import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// react icons
import { flexCenter } from "themes/commonStyles";
import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DialogContext } from "./context/DialogContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ProfileSettings = () => {
  const languages = [
    { language: "english", code: "gb" },
    { language: "german", code: "de" },
  ];

  const settings = [
    { title: "Sign up" },
    { title: "Login" },
    { title: "Host your home" },
    { title: "Help" },
    { title: "Contact us" },
  ];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    anchorElSettings,
    setAnchorElSettings,
  ] = React.useState<null | HTMLElement>(null);
  const openMenu: boolean = Boolean(anchorEl);
  const settingsOpen: boolean = Boolean(anchorElSettings);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClose = () => {
    setAnchorElSettings(null);
  };

  // @ts-ignore
  const { setOpen } = useContext(DialogContext);

  return (
    <Box sx={flexCenter}>
      <Button  variant="text" onClick={() => setOpen(true)}>
        Become A Host
      </Button>
      <Stack>
        <Button
          id="basic-button"
          aria-controls={openMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
          onClick={handleClick}
        >
          <LanguageIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {languages.map((item, index: number) => (
            <MenuItem key={index} onClick={handleClose}>
              <img
                style={{ marginRight: 5 }}
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${item.code}.png`}
                srcSet={`https://flagcdn.com/w20/${item.code}.png`}
              />
              {item.language}
            </MenuItem>
          ))}
        </Menu>
        <Button
          id="settings-button"
          aria-controls={settingsOpen ? "settings-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={settingsOpen ? "true" : undefined}
          onClick={handleSettingsClick}
          sx={{
            borderRadius: 10,
            border: "1px solid #ddd",
          }}
        >
          <Stack>
            <MenuIcon />
            <AccountCircleIcon />
          </Stack>
        </Button>

        <Menu
          sx={{ mt: "50px" }}
          id="settings-menu"
          anchorEl={anchorElSettings}
          open={settingsOpen}
          onClose={handleSettingsClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {settings.map((item, index: number) => (
            <MenuItem
              key={index}
              onClick={handleSettingsClose}
              sx={{ width: 200, height: 40 }}
            >
              <Typography> {item.title}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    </Box>
  );
};

export default ProfileSettings;
